import React from "react";

import { Typography, Grid } from "@mui/material";

import ProjectPage from "../../../components/project-page";
import ThumbnailImage from "../../../components/thumbnail";
import { graphql, useStaticQuery } from "gatsby";
import YoutubeEmbed from "../../../components/youtube";
import CurveEditor from "../../../components/curve-editor/editor";
import { InlineCode } from "../../../components/mdx-layout";

const query = graphql`
  query CurveEditorQuery {
    image1: file(relativePath: { eq: "projects/curve-editor/curve1.png" }) {
      ...projectThumbnailImage
    }

    image2: file(relativePath: { eq: "projects/curve-editor/curve2.png" }) {
      ...projectThumbnailImage
    }

    image3: file(relativePath: { eq: "projects/curve-editor/curve3.png" }) {
      ...projectThumbnailImage
    }
  }
`;

const CurveEditorPage: React.FC = () => {
  const projectName = `Curve Editor`;
  const data = useStaticQuery(query);

  return (
    <ProjectPage projectName={projectName} projectSubheader="Fall 2008">
      <Typography variant="body1" component="p">
        This curve editor supports bezier, cubic splines, b-splines, and
        polynomial curves that you can edit and manipulate with numerous control
        points. This project is a demo for visualizing how different curves can
        be utilized. This project was written from scratch orginally in{" "}
        <InlineCode>C++</InlineCode> but is currently being ported to{" "}
        <InlineCode>Typescript</InlineCode> for an web based demo below.
      </Typography>
      <br />
      <Typography variant="h5" component="h2">
        Screenshots
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ThumbnailImage file={data.image1} alt="Screenshot 1" />
        </Grid>
        <Grid item xs={4}>
          <ThumbnailImage file={data.image2} alt="Screenshot 2" />
        </Grid>
        <Grid item xs={4}>
          <ThumbnailImage file={data.image3} alt="Screenshot 3" />
        </Grid>
      </Grid>
      <br />
      <YoutubeEmbed id="jOjn8ApxtvU" />
      <br />
      <Typography variant="h5" component="h2">
        Demo (Work in progress)
      </Typography>
      <br />
      <CurveEditor />
    </ProjectPage>
  );
};

export default CurveEditorPage;
